import React, { useEffect, useRef, useState } from "react"
import "./Account.styles.scss"
import { Row } from "antd"
import AccountsSection from "../../components/Account/AccountsSection/AccountsSection.component"
import BasicSettingsSection from "../../components/Account/BasicSettingsSection/BasicSettingsSection.component"
import { useDispatch, useSelector } from "react-redux"
import { StringParam, useQueryParam, useQueryParams } from "use-query-params"
import {
  addAccount,
  createStripeSession,
  resyncInteractions,
  showUnsubscribeNotification,
} from "../../redux/Accounts/Accounts.actions"

import { useHistory } from "react-router"
import EmailTemplates from "../../components/Account/EmailTemplates/EmailTemplates.component"
import SignaturesEditor from "../../components/Account/SignaturesEditor/SignaturesEditor.component"
import SyncingContactsModal from "../../components/Dashboard/SyncingContactsModal/SyncingContactsModal.component"
import AccountNavSections from "../../components/Dashboard/ContactProfileDrawer/components/AccountNavSections/AccountNavSections.component"
import {
  getUserDetails,
  hideOnboardingFlow,
  updateTimezone,
} from "../../redux/User/User.actions"
import { fetchTemplate } from "../../redux/Messages/Messages.actions"
import moment from "moment"
import MyInfoSection from "../../components/Account/MyInfoSection/MyInfoSection.component"
import AppsSection from "../../components/Account/AppsSection/AppsSection.component"
import TeamsSection from "../../components/Account/TeamsSection/TeamsSection.component"

const AccountPage = () => {
  // TODO: Show team section only for admins
  const role = useSelector((state) => state.UserState.role)

  const tabs = [
    { id: "MyInfo", name: "My Info", width: 120, paddingLeft: 0 },
    { id: "BasicSettings", name: "Basic Settings", width: 120, paddingLeft: 120 },
    { id: "Accounts", name: "Accounts", width: 120, paddingLeft: 240 },
    { id: "Mobile&Browser", name: "Apps", width: 140, paddingLeft: 360 },
    { id: "Billing", name: "Billing", width: 120, paddingLeft: 500 },
    { id: "Templates", name: "Templates", width: 140, paddingLeft: 620 },
    { id: "Signatures", name: "Signatures", width: 140, paddingLeft: 760 },
    role === "admin" && { id: "Teams", name: "Teams", width: 140, paddingLeft: 900 },
  ]

  const history = useHistory()
  const [code] = useQueryParam("code", StringParam)
  const [service] = useQueryParam("service", StringParam)
  const [grant_id] = useQueryParam("grant_id", StringParam)
  const [extensionInstalled] = useQueryParam("li_ext", StringParam)

  const [state] = useQueryParam("state", StringParam)
  const [section] = useQueryParam("section", StringParam)
  const [templateId] = useQueryParam("template_id", StringParam)

  const dispatch = useDispatch()
  const [showTemplateModal, setShowTemplateModal] = useState(false)

  const [showSyncingContactsModal, setShowSyncingContactsModal] = useState(false)
  const [disableClosableSyncingModal, setDisableClosableSyncingModal] =
    useState(false)
  const status = useSelector((state) => state.UserState.status)
  const showOnboardingFlow = useSelector(
    (state) => state.UserState.show_onboarding_flow
  )
  const [queryParams] = useQueryParams({
    unsubscribe: StringParam,
  })

  const hasTeamParam = () => {
    return window.location.hash === "#teams"
  }

  const teamSectionRef = useRef(null)

  useEffect(() => {
    if (hasTeamParam()) {
      teamSectionRef.current.scrollIntoView()
    }

    if (code) {
      dispatch(addAccount(code, state, status))
    } else if (grant_id) {
      if (showOnboardingFlow) {
        dispatch(resyncInteractions(grant_id, true))
        dispatch(hideOnboardingFlow())
        history.push("/dashboard?onboarding_step=3")
      } else {
        dispatch(resyncInteractions(grant_id, true))
        history.replace({ search: "" })
        if (status === "rookie") {
          setDisableClosableSyncingModal(true)
          setShowSyncingContactsModal(true)
          dispatch(updateTimezone(moment.tz.guess()))
          setTimeout(() => setDisableClosableSyncingModal(false), 2500)
        }
      }
    }
    // eslint-disable-next-line
  }, [code, service])

  useEffect(() => {
    dispatch(getUserDetails())
    if (extensionInstalled) {
      deleteCookie("li_ext")
    }

    if (section) {
      scrollToSection(section)
      if (section === "Templates" && templateId) {
        setShowTemplateModal(true)
        dispatch(fetchTemplate(templateId))
      }
    }

    if (queryParams.unsubscribe === "true") {
      dispatch(showUnsubscribeNotification())
    }
    // eslint-disable-next-line
  }, [])

  const deleteCookie = (name) => {
    const c_value = "; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/"
    document.cookie = name + "=" + c_value
  }

  const scrollToSection = (section) => {
    const Scroll = require("react-scroll")
    const scroller = Scroll.scroller

    const sectionId = `section${section}`

    // Somewhere else, even another file
    scroller.scrollTo(sectionId, {
      duration: 1500,
      delay: 100,
      smooth: true,
      containerId: "AccountSettingsSections",
      offset: -50,
    })
  }

  return (
    <div className="AccountSettings_Container">
      <h1>Do you want to update any of your information?</h1>
      {showSyncingContactsModal && (
        <SyncingContactsModal
          onClose={() => {
            if (!disableClosableSyncingModal) {
              setShowSyncingContactsModal(false)
              history.push("/dashboard?welcome_step=2")
            }
          }}
          visible={showSyncingContactsModal}
        />
      )}
      <Row className={"AccountSettings_Nav"}>
        {" "}
        <AccountNavSections
          containerId={"AccountSettingsSections"}
          defaultTab={hasTeamParam() ? tabs[7] : tabs[0]}
          tabs={tabs}
        />
      </Row>
      <div className={"AccountSettings_Sections"} id={"AccountSettingsSections"}>
        <section id="sectionMyInfo">
          <MyInfoSection />
        </section>
        <section id="sectionBasicSettings">
          <BasicSettingsSection />
        </section>
        <section id="sectionAccounts">
          <AccountsSection />
        </section>
        <section id="sectionMobile&Browser">
          <AppsSection />
        </section>
        <section id="sectionBilling">
          <div className="AccountSettings_Billing Wrapper">
            <h2>Billing</h2>
            <div>
              <button
                style={{ margin: "10px auto 0 0" }}
                onClick={() => dispatch(createStripeSession())}
                className="SubmitButton"
              >
                Manage Subscription
              </button>
            </div>
          </div>
        </section>
        <section id="sectionTemplates">
          <div className="Wrapper">
            <h2>Templates</h2>
            <EmailTemplates openTemplateEditor={showTemplateModal} />
          </div>
        </section>
        <section id="sectionSignatures" className="AccountSettings_Signatures">
          <div className="Wrapper">
            <h2>Signatures</h2>
            <p>These will be used when composing messages</p>
            <SignaturesEditor />
          </div>
        </section>
        {role === "admin" && (
          <section
            ref={teamSectionRef}
            id="sectionTeams"
            name="sectionTeams"
            className="AccountSettings_Teams"
          >
            <TeamsSection />
          </section>
        )}
      </div>
    </div>
  )
}

export default AccountPage

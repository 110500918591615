import {
  ADD_REMINDER,
  ADD_REMINDER_FAIL,
  ADD_REMINDER_SUCCESS,
  CLEAR_REDUX_REMINDERS_STATE,
  COMPLETE_REMINDER_SUCCESS,
  DELETE_REMINDER_SUCCESS,
  FETCH_EVENTS,
  FETCH_EVENTS_FAIL,
  FETCH_EVENTS_SUCCESS,
  FETCH_REMINDERS_SUCCESS,
  IGNORE_SPECIAL_DATE,
  IGNORE_SPECIAL_DATE_FAIL,
  SET_INITIAL_REDUX_REMINDERS_STATE,
  UPDATE_REMINDER_SUCCESS,
} from "./Reminders.types"
import { LOGOUT_SUCCESS } from "../User/User.types"
import * as _ from "lodash"
import {
  APPROVE_TRIGGER,
  APPROVE_TRIGGER_FAIL,
  IGNORE_TRIGGER,
  IGNORE_TRIGGER_FAIL,
  UPDATE_TRIGGER,
  UPDATE_TRIGGER_FAIL,
} from "../Triggers/Triggers.types"

const INITIAL_STATE = {
  reminders: [],
  special_dates: [],
  triggers: [],
  job_change: [],
  adding: false,
  loading_more: false,
  fetching: false,
}

export const remindersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_REMINDERS_SUCCESS:
      return {
        ...state,
        reminders: action.reminders,
      }
    case ADD_REMINDER:
      return {
        ...state,
        adding: true,
      }
    case ADD_REMINDER_SUCCESS:
      return {
        ...state,
        reminders: _.sortBy(
          [action.reminder, ...state.reminders],
          (item) => item.due_at
        ),
        adding: false,
      }
    case ADD_REMINDER_FAIL:
      return {
        ...state,
        adding: false,
      }
    case COMPLETE_REMINDER_SUCCESS:
      return {
        ...state,
        reminders: state.reminders.filter(
          (reminder) => reminder.id !== action.reminder.id
        ),
      }
    case DELETE_REMINDER_SUCCESS:
      return {
        ...state,
        reminders: state.reminders.filter(
          (reminder) => reminder.id !== action.reminder.id
        ),
      }
    case FETCH_EVENTS:
      return {
        ...state,
        loading_more: true,
      }
    case FETCH_EVENTS_SUCCESS:
      return {
        ...state,
        special_dates:
          action.page === 1
            ? action.special_dates
            : [...state.special_dates, ...action.special_dates],
        triggers:
          action.page === 1
            ? action.triggers
            : [...state.triggers, ...action.triggers],
        job_change:
          action.page === 1
            ? action.job_change
            : [...state.job_change, ...action.job_change],
        loading_more: false,
        fetching: false,
      }
    case FETCH_EVENTS_FAIL:
      return {
        ...state,
        loading_more: false,
        fetching: false,
      }
    case IGNORE_SPECIAL_DATE:
      return {
        ...state,
        fetching: true,
      }
    case IGNORE_SPECIAL_DATE_FAIL:
      return {
        ...state,
        fetching: false,
      }
    case UPDATE_TRIGGER:
      return {
        ...state,
        fetching: true,
      }
    case UPDATE_TRIGGER_FAIL:
      return {
        ...state,
        fetching: false,
      }
    case APPROVE_TRIGGER:
      return {
        ...state,
        fetching: true,
      }
    case APPROVE_TRIGGER_FAIL:
      return {
        ...state,
        fetching: false,
      }
    case IGNORE_TRIGGER:
      return {
        ...state,
        fetching: true,
      }
    case IGNORE_TRIGGER_FAIL:
      return {
        ...state,
        fetching: false,
      }
    case UPDATE_REMINDER_SUCCESS:
      return {
        ...state,
        reminders: state.reminders.map((reminder) => {
          if (reminder.id === action.reminder.id) {
            return action.reminder
          }
          return reminder
        }),
      }
    case LOGOUT_SUCCESS:
      return { ...INITIAL_STATE }
    case CLEAR_REDUX_REMINDERS_STATE:
      return {}
    case SET_INITIAL_REDUX_REMINDERS_STATE:
      return { ...INITIAL_STATE }
    default:
      return state
  }
}

export const AUTHENTICATE_USER = "AUTHENTICATE_USER"
export const AUTHENTICATE_USER_SUCCESS = "AUTHENTICATE_USER_SUCCESS"
export const AUTHENTICATE_USER_FAIL = "AUTHENTICATE_USER_FAIL"

export const REGISTER_USER = "REGISTER_USER"
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS"
export const REGISTER_USER_FAIL = "REGISTER_USER_FAIL"

export const UPDATE_PROFILE = "UPDATE_PROFILE"
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS"
export const UPDATE_PROFILE_FAIL = "UPDATE_PROFILE_FAIL"

export const CREATE_TEAM = "CREATE_TEAM"
export const CREATE_TEAM_SUCCESS = "CREATE_TEAM_SUCCESS"
export const CREATE_TEAM_FAIL = "CREATE_TEAM_FAIL"

export const FETCH_TEAM = "FETCH_TEAM"
export const FETCH_TEAM_SUCCESS = "FETCH_TEAM_SUCCESS"
export const FETCH_TEAM_FAIL = "FETCH_TEAM_FAIL"

export const UPDATE_TEAM = "UPDATE_TEAM"
export const UPDATE_TEAM_SUCCESS = "UPDATE_TEAM_SUCCESS"
export const UPDATE_TEAM_FAIL = "UPDATE_TEAM_FAIL"

export const DELETE_TEAM = "DELETE_TEAM"
export const DELETE_TEAM_SUCCESS = "DELETE_TEAM_SUCCESS"
export const DELETE_TEAM_FAIL = "DELETE_TEAM_FAIL"

export const LEAVE_TEAM = "LEAVE_TEAM"
export const LEAVE_TEAM_SUCCESS = "LEAVE_TEAM_SUCCESS"
export const LEAVE_TEAM_FAIL = "LEAVE_TEAM_FAIL"

export const REMOVE_MEMBER_TEAM = "REMOVE_MEMBER_TEAM"
export const REMOVE_MEMBER_TEAM_SUCCESS = "REMOVE_MEMBER_TEAM_SUCCESS"
export const REMOVE_MEMBER_TEAM_FAIL = "REMOVE_MEMBER_TEAM_FAIL"
export const ADD_TEAM_MEMBER = "ADD_TEAM_MEMBER"
export const ADD_TEAM_MEMBER_SUCCESS = "ADD_TEAM_MEMBER_SUCCESS"
export const ADD_TEAM_MEMBER_FAIL = "ADD_TEAM_MEMBER_FAIL"

export const JOIN_TO_TEAM = "JOIN_TO_TEAM"
export const JOIN_TO_TEAM_SUCCESS = "JOIN_TO_TEAM_MEMBER_SUCCESS"
export const JOIN_TO_TEAM_FAIL = "JOIN_TO_TEAM_FAIL"

export const CANCEL_TEAM_INVITE = "CANCEL_TEAM_INVITE"
export const CANCEL_TEAM_INVITE_SUCCESS = "CANCEL_TEAM_INVITE_SUCCESS"
export const CANCEL_TEAM_INVITE_FAIL = "CANCEL_TEAM_INVITE_FAIL"

export const UPDATE_TEAM_SETTINGS = "UPDATE_TEAM_SETTINGS"
export const UPDATE_TEAM_SETTINGS_SUCCESS = "UPDATE_TEAM_SETTINGS_SUCCESS"
export const UPDATE_TEAM_SETTINGS_FAIL = "UPDATE_TEAM_SETTINGS_FAIL"

export const REMOVE_TEAM_INVITATION = "REMOVE_TEAM_INVITATION"
export const REMOVE_TEAM_INVITATION_SUCCESS = "REMOVE_TEAM_INVITATION_MEMBER_SUCCESS"
export const REMOVE_TEAM_INVITATION_FAIL = "REMOVE_TEAM_INVITATION_FAIL"

export const RESEND_TEAM_INVITATION = "RESEND_TEAM_INVITATION"
export const RESEND_TEAM_INVITATION_SUCCESS = "RESEND_TEAM_INVITATION_MEMBER_SUCCESS"
export const RESEND_TEAM_INVITATION_FAIL = "RESEND_TEAM_INVITATION_FAIL"

export const UPDATE_AVATAR = "UPDATE_AVATAR"
export const UPDATE_AVATAR_SUCCESS = "UPDATE_AVATAR_SUCCESS"
export const UPDATE_AVATAR_FAIL = "UPDATE_AVATAR_FAIL"

export const LOGOUT = "LOGOUT"
export const LOGOUT_START = "LOGOUT_START"
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS"
export const LOGOUT_FAIL = "LOGOUT_FAIL"

export const GET_STATUS = "GET_STATUS"
export const GET_STATUS_SUCCESS = "GET_STATUS_SUCCESS"
export const GET_STATUS_FAIL = "GET_STATUS_FAIL"

export const PASSWORD_RESET = "PASSWORD_RESET"
export const PASSWORD_RESET_SUCCESS = "PASSWORD_RESET_SUCCESS"
export const PASSWORD_RESET_FAIL = "PASSWORD_RESET_FAIL"

export const SAVE_PASSWORD = "SAVE_PASSWORD"
export const SAVE_PASSWORD_SUCCESS = "SAVE_PASSWORD_SUCCESS"
export const SAVE_PASSWORD_FAIL = "SAVE_PASSWORD_FAIL"

export const CLEAR_REDUX_USER_STATE = "CLEAR_REDUX_USER_STATE"
export const SET_INITIAL_REDUX_USER_STATE = "SET_INITIAL_REDUX_USER_STATE"

export const SET_REFRESHING = "SET_REFRESHING"

export const GET_TRIAL_INFO = "GET_TRIAL_INFO"
export const GET_TRIAL_INFO_SUCCESS = "GET_TRIAL_INFO_SUCCESS"
export const GET_TRIAL_INFO_FAIL = "GET_TRIAL_INFO_FAIL"

export const GET_USER_DETAILS = "GET_USER_DETAILS"
export const GET_USER_DETAILS_SUCCESS = "GET_USER_DETAILS_SUCCESS"
export const GET_USER_DETAILS_FAIL = "GET_USER_DETAILS_FAIL"

export const GET_USER_FINISHED_TASKS = "GET_USER_FINISHED_TASKS"
export const GET_USER_FINISHED_TASKS_SUCCESS = "GET_USER_FINISHED_TASKS_SUCCESS"
export const GET_USER_FINISHED_TASKS_FAIL = "GET_USER_FINISHED_TASKS_FAIL"

export const RESET_LOGGING_OUT_STATE = "RESET_LOGGING_OUT_STATE"

export const UPDATE_TIMEZONE = "UPDATE_TIMEZONE"

export const LOGOUT_ALL_DEVICES = "LOGOUT_ALL_DEVICES"
export const LOGOUT_ALL_DEVICES_SUCCESS = "LOGOUT_ALL_DEVICES_SUCCESS"
export const LOGOUT_ALL_DEVICES_FAIL = "LOGOUT_ALL_DEVICES_FAIL"

export const GET_API_KEYS = "GET_API_KEYS"
export const GET_API_KEYS_SUCCESS = "GET_API_KEYS_SUCCESS"
export const GET_API_KEYS_FAIL = "GET_API_KEYS_FAIL"

export const CREATE_API_KEY = "CREATE_API_KEY"
export const CREATE_API_KEY_SUCCESS = "CREATE_API_KEY_SUCCESS"
export const CREATE_API_KEY_FAIL = "CREATE_API_KEY_FAIL"

export const REGENERATE_API_KEY = "REGENERATE_API_KEY"
export const REGENERATE_API_KEY_SUCCESS = "REGENERATE_API_KEY_SUCCESS"
export const REGENERATE_API_KEY_FAIL = "REGENERATE_API_KEY_FAIL"

export const DELETE_API_KEY = "DELETE_API_KEY"
export const DELETE_API_KEY_SUCCESS = "DELETE_API_KEY_SUCCESS"
export const DELETE_API_KEY_FAIL = "DELETE_API_KEY_FAIL"
export const UPDATE_SHOW_FOCUS_MODE = "UPDATE_SHOW_FOCUS_MODE"
export const SEND_FOCUS_MODE_EVENT = "SEND_FOCUS_MODE_EVENT"

export const HIDE_ONBOARDING_FLOW = "HIDE_ONBOARDING_FLOW"

export const SET_REDIRECTING = "SET_REDIRECTING"

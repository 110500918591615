import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Checkbox, Dropdown, List, Menu, Spin } from "antd"
import "./EventList.styles.scss"
import EventCard from "./EventCard/EventCard.component"
import { ReactComponent as ManAndThumb } from "./../../../images/ManAndThumb.svg"
import { useSelector } from "react-redux"
import { MoreOutlined } from "@ant-design/icons"
import RelatableLoader from "../../UI/RelatableLoader/RelatableLoader.component"

const EventList = ({ fetching, loading_more, onLoadMore, count, page }) => {
  const special_dates = useSelector((state) => state.RemindersState.special_dates)
  const triggers = useSelector((state) => state.RemindersState.triggers)
  const job_change = useSelector((state) => state.RemindersState.job_change)

  const [list, setList] = useState([])
  const [hideLearnMoreButton, setHideLearnMoreButton] = useState(false)
  const [eventsFilters, setEventsFilters] = useState([
    "trigger",
    "special_date",
    "birthday",
    "job_change",
  ])
  const [first_page_loading, setFirstPageLoading] = useState(true)

  useEffect(() => {
    let newList = []
    if (eventsFilters.includes("trigger") && triggers.length > 0) {
      newList = [...newList, ...triggers]
      // const showMoreButton = triggers.length === count
    }

    if (
      (eventsFilters.includes("special_date") ||
        eventsFilters.includes("birthday")) &&
      special_dates.length > 0
    ) {
      newList = [...newList, ...special_dates]
      // const showMoreButton = triggers.length === count
    }

    if (eventsFilters.includes("job_change") && job_change.length > 0) {
      newList = [...newList, ...job_change]
      // const showMoreButton = triggers.length === count
    }

    setHideLearnMoreButton(newList.length / count < page)
    setList(newList.slice(0, count * page))
    setFirstPageLoading(false)
    // eslint-disable-next-line
  }, [triggers, special_dates, job_change, eventsFilters])

  const handleUpdateEventsFilters = (newFilters) => {
    setEventsFilters(newFilters)
    setHideLearnMoreButton(false)
  }

  const handleClickOnLoadingMore = () => {
    setList(
      list.concat(
        [...new Array(count)].map(() => ({
          loading: true,
          name: null,
          date: null,
          type: "empty",
          person: {
            id: null,
            full_name: null,
            avatar: null,
            spheres: [],
          },
        }))
      )
    )
    onLoadMore()
  }

  const loadMore =
    !hideLearnMoreButton && !fetching && !loading_more ? (
      <div className="EventList_LoadingMore">
        <button onClick={handleClickOnLoadingMore}>Load more...</button>
      </div>
    ) : null

  return (
    <div className="EventList">
      <div className="EventList_Filters">
        <div className="EventList_Filters_Main">
          <button
            onClick={() =>
              handleUpdateEventsFilters([
                "trigger",
                "special_date",
                "birthday",
                "job_change",
              ])
            }
            className={
              eventsFilters.includes("trigger") &&
              eventsFilters.includes("special_date") &&
              eventsFilters.includes("birthday") &&
              eventsFilters.includes("job_change")
                ? "active--all"
                : "inactive"
            }
          >
            All Suggestions
          </button>
          <button
            onClick={() => handleUpdateEventsFilters(["trigger"])}
            className={
              eventsFilters.includes("trigger") ? "active--trigger" : "inactive"
            }
          >
            Stay in Touch Reminders
          </button>
          <button
            onClick={() => {
              handleUpdateEventsFilters(["special_date", "birthday"])
            }}
            className={
              eventsFilters.includes("special_date") &&
              eventsFilters.includes("birthday")
                ? "active--special_date"
                : "inactive"
            }
          >
            Birthdays & Special Dates
          </button>
          <button
            onClick={() => handleUpdateEventsFilters(["job_change"])}
            className={
              eventsFilters.includes("job_change")
                ? "active--job_change"
                : "inactive"
            }
          >
            News
          </button>
        </div>
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key={1}>
                <Checkbox
                  checked={eventsFilters.includes("monthly_special_dates")}
                  onChange={() =>
                    handleUpdateEventsFilters(["monthly_special_dates"])
                  }
                >
                  Show the next 30 days of birthdays and special dates
                </Checkbox>
              </Menu.Item>
            </Menu>
          }
          trigger={["click"]}
          placement="bottomRight"
        >
          <button className="more_options">
            <MoreOutlined />
          </button>
        </Dropdown>
      </div>
      {list.length > 0 && eventsFilters?.length > 0 ? (
        <List
          loading={{
            spinning: fetching,
            indicator: <RelatableLoader quote={true} loading={first_page_loading} />,
          }}
          itemLayout="horizontal"
          loadMore={loadMore}
          dataSource={list.filter((item) =>
            ["empty", ...eventsFilters].includes(item.type)
          )}
          // dataSource={list}
          renderItem={(item, i) => (
            <EventCard item={item} i={i} count={count} page={page} />
          )}
        />
      ) : fetching ? (
        <div className="Dashboard_Cards_Loading">
          <RelatableLoader />
        </div>
      ) : (
        <div className="Dashboard_Cards_Empty">
          <ManAndThumb />
          <div>
            <h3>Nothing coming up!</h3>
            <span>
              When you add birthdays, anniversaries, and other special dates to your
              relationships, we’ll let you know if anyone has something coming up.
            </span>
          </div>
        </div>
      )}
    </div>
  )
}

EventList.propTypes = {
  fetching: PropTypes.bool,
  loading_more: PropTypes.bool,
  onLoadMore: PropTypes.func,
  count: PropTypes.number,
}

EventList.defaultProps = {
  fetching: false,
  loading_more: false,
  count: 3,
}

export default EventList

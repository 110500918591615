import {
  CLEAR_REDUX_APP_STATE,
  DESELECT_CONTACT,
  DESELECT_CONTACTS,
  DESELECT_PARTICIPANT,
  DESELECT_PARTICIPANTS,
  FILL_DATA_IN_SELECTED_CONTACTS,
  OPEN_DRAWER_TAB,
  RESET_SELECTED_CONTACTS,
  SELECT_CONTACT,
  SELECT_CONTACTS,
  SELECT_PARTICIPANT,
  SELECT_PARTICIPANTS,
  SET_ACTIVE_TAB,
  SET_CONTACT_DRAWER_MODE,
  SET_CONTACT_DRAWER_VISIBLE,
  SET_CURRENT_LOCATION_KEY,
  SET_INIT_CC,
  SET_INITIAL_REDUX_APP_STATE,
  SET_LEFT_RAIL_COLLAPSED,
  SET_MESSAGE_SENDER_VISIBLE,
  SET_MESSAGE_SENT,
  SET_RIGHT_PANEL_TAB,
  SET_RIGHT_RAIL_COLLAPSED,
  SET_SCHEDULED_SPLIT_MESSAGE_SENDER_VISIBLE,
  SET_SELECTED_CONTACT_ID,
  SET_SHOW_START_BUILDING_CARD,
  SET_SPHERE_MODAL_VISIBLE,
  SET_SPLIT_MESSAGE_SENDER_VISIBLE,
  SET_USER_PANEL_MODAL_VISIBLE,
  SET_VISIBLE_CONTACT_ID,
  TOGGLE_SUGGESTED_MESSAGES_PANEL,
} from "./App.types"
import * as _ from "lodash"
import { LOCATION_CHANGE } from "connected-react-router"
import { EDIT_NOTE } from "../Interactions/Interactions.types"
import { LOGOUT_SUCCESS } from "../User/User.types"
import {
  ADD_PARTICIPANTS_TO_SPHERES,
  ADD_PARTICIPANTS_TO_SPHERES_FAIL,
  ADD_PARTICIPANTS_TO_SPHERES_SUCCESS,
  ADD_TO_SPHERES,
  ADD_TO_SPHERES_FAIL,
  ADD_TO_SPHERES_SUCCESS,
  ARCHIVE_PARTICIPANTS,
  ARCHIVE_PARTICIPANTS_FAIL,
  ARCHIVE_PARTICIPANTS_SUCCESS,
} from "../Participants/Participants.types"
import {
  EDIT_CONTACT,
  EDIT_CONTACT_FAIL,
  EDIT_CONTACT_SUCCESS,
  EDIT_CONTACTS,
  EDIT_CONTACTS_FAIL,
  EDIT_CONTACTS_SUCCESS,
} from "../People/People.types"
import {
  APPROVE_TRIGGER,
  APPROVE_TRIGGER_FAIL,
  APPROVE_TRIGGER_SUCCESS,
  IGNORE_TRIGGER,
  IGNORE_TRIGGER_FAIL,
  IGNORE_TRIGGER_SUCCESS,
} from "../Triggers/Triggers.types"

const INITIAL_STATE = {
  contactDrawerVisible: false,
  contactDrawerMode: "view",
  activeDrawerTab: null,
  visibleContactID: null,
  visibleContactFriendlyID: null,
  selected_contact_id: null,
  selected_contacts: [],
  selected_contacts_ids: [],
  selected_participants_ids: [],
  currentLocationKey: null,
  messageSenderVisible: false,
  splitMessageSenderVisible: false,
  scheduledSplitMessageSenderVisible: false,
  activeRightPanelTab: "welcome",
  leftRailCollapsed: false,
  rightRailCollapsed: false,
  sphereModalVisible: false,
  userPanelModalVisible: false,
  activeSphereId: null,
  initCC: [],
  sphereModalTab: "reminders",
  message_sent: false,
  showStartBuildingCard: false,
  showSuggestedMessages: false,
  repetitiveAction: false,
}

export const appState = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOCATION_CHANGE:
      const resetSelectedContacts =
        !/\/database/.test(action.payload.location.pathname) &&
        !state.scheduledSplitMessageSenderVisible

      return {
        ...state,
        selected_contacts: resetSelectedContacts ? [] : state.selected_contacts,
        selected_contacts_ids: resetSelectedContacts
          ? []
          : state.selected_contacts_ids,
      }
    case SET_MESSAGE_SENT:
      return {
        ...state,
        message_sent: action.message_sent,
      }
    case SET_CONTACT_DRAWER_VISIBLE:
      return {
        ...state,
        contactDrawerVisible: action.visible,
      }
    case SET_MESSAGE_SENDER_VISIBLE:
      return {
        ...state,
        messageSenderVisible: action.visible,
      }
    case SET_SPLIT_MESSAGE_SENDER_VISIBLE:
      return {
        ...state,
        splitMessageSenderVisible: action.visible,
      }
    case SET_SCHEDULED_SPLIT_MESSAGE_SENDER_VISIBLE:
      return {
        ...state,
        scheduledSplitMessageSenderVisible: action.visible,
      }
    case SET_CONTACT_DRAWER_MODE:
      return {
        ...state,
        contactDrawerMode: action.mode,
      }
    case SET_VISIBLE_CONTACT_ID:
      return {
        ...state,
        visibleContactID: action.id,
        visibleContactFriendlyID: action.slug,
      }
    case SELECT_CONTACTS:
      return {
        ...state,
        selected_contacts: _.uniqBy(
          [...state.selected_contacts, ...action.selected],
          "id"
        ),
        selected_contacts_ids: _.uniq([
          ...state.selected_contacts_ids,
          ...action.selected.map((s) => s.id),
        ]),
      }
    case DESELECT_CONTACTS:
      return {
        ...state,
        selected_contacts: [],
        selected_contacts_ids: [],
      }
    case FILL_DATA_IN_SELECTED_CONTACTS:
      return {
        ...state,
        selected_contacts: action.selected,
      }
    case SELECT_CONTACT:
      return {
        ...state,
        selected_contacts: _.uniqBy(
          [...state.selected_contacts, action.selected],
          "id"
        ),
        selected_contacts_ids: _.uniq([
          ...state.selected_contacts_ids,
          action.selected.id,
        ]),
      }
    case DESELECT_CONTACT:
      return {
        ...state,
        selected_contacts: _.uniqBy(
          [
            ...state.selected_contacts.filter(
              (key) => key.id !== action.selected.id
            ),
          ],
          "id"
        ),
        selected_contacts_ids: _.uniq([
          ...state.selected_contacts_ids.filter((key) => key !== action.selected.id),
        ]),
      }
    case RESET_SELECTED_CONTACTS:
      return {
        ...state,
        selected_contacts: [],
        selected_contacts_ids: [],
      }
    case SELECT_PARTICIPANTS:
      return {
        ...state,
        selected_participants_ids: _.uniq([
          ...state.selected_participants_ids,
          ...action.selected_ids,
        ]),
      }
    case DESELECT_PARTICIPANTS:
      return {
        ...state,
        selected_participants_ids: [],
      }
    case SELECT_PARTICIPANT:
      return {
        ...state,
        selected_participants_ids: _.uniq([
          ...state.selected_participants_ids,
          action.selected_id,
        ]),
      }
    case DESELECT_PARTICIPANT:
      return {
        ...state,
        selected_participants_ids: _.uniq([
          ...state.selected_participants_ids.filter(
            (key) => key !== action.selected_id
          ),
        ]),
      }
    case SET_ACTIVE_TAB:
      return {
        ...state,
        activeDrawerTab: action.tab,
      }
    case SET_LEFT_RAIL_COLLAPSED:
      return {
        ...state,
        leftRailCollapsed: action.leftRailCollapsed,
      }
    case SET_RIGHT_RAIL_COLLAPSED:
      return {
        ...state,
        rightRailCollapsed: action.rightRailCollapsed,
      }
    case EDIT_NOTE:
      return {
        ...state,
        activeDrawerTab: "note",
      }
    case OPEN_DRAWER_TAB:
      return {
        ...state,
        activeDrawerTab: action.tab_name,
      }
    case SET_CURRENT_LOCATION_KEY:
      return {
        ...state,
        currentLocationKey: action.value,
      }
    case LOGOUT_SUCCESS:
      return { ...INITIAL_STATE }
    case SET_RIGHT_PANEL_TAB:
      return {
        ...state,
        activeRightPanelTab: action.value,
      }
    case SET_SPHERE_MODAL_VISIBLE:
      return {
        ...state,
        activeSphereId: action.sphere_id,
        sphereModalVisible: action.visible,
        sphereModalTab: action.tab,
      }
    case SET_USER_PANEL_MODAL_VISIBLE:
      return {
        ...state,
        userPanelModalVisible: action.visible,
      }
    case SET_SHOW_START_BUILDING_CARD:
      return {
        ...state,
        showStartBuildingCard: action.value,
      }
    case SET_INIT_CC:
      return {
        ...state,
        initCC: action.initCC,
      }
    case CLEAR_REDUX_APP_STATE:
      return {}
    case SET_INITIAL_REDUX_APP_STATE:
      return { ...INITIAL_STATE }
    case TOGGLE_SUGGESTED_MESSAGES_PANEL:
      return {
        ...state,
        showSuggestedMessages: !state.showSuggestedMessages,
      }
    case SET_SELECTED_CONTACT_ID:
      return {
        ...state,
        selected_contact_id: action.id,
      }
    case ADD_TO_SPHERES:
      return {
        ...state,
        repetitiveAction: true,
      }
    case ADD_PARTICIPANTS_TO_SPHERES:
      return {
        ...state,
        repetitiveAction: true,
      }
    case ARCHIVE_PARTICIPANTS:
      return {
        ...state,
        repetitiveAction: true,
      }
    case EDIT_CONTACTS:
      return {
        ...state,
        repetitiveAction: true,
      }
    case APPROVE_TRIGGER:
      return {
        ...state,
        repetitiveAction: true,
      }
    case IGNORE_TRIGGER:
      return {
        ...state,
        repetitiveAction: true,
      }
    case ADD_TO_SPHERES_FAIL:
      return {
        ...state,
        repetitiveAction: false,
      }
    case ADD_PARTICIPANTS_TO_SPHERES_FAIL:
      return {
        ...state,
        repetitiveAction: false,
      }
    case ARCHIVE_PARTICIPANTS_FAIL:
      return {
        ...state,
        repetitiveAction: false,
      }
    case EDIT_CONTACTS_FAIL:
      return {
        ...state,
        repetitiveAction: false,
      }
    case APPROVE_TRIGGER_FAIL:
      return {
        ...state,
        repetitiveAction: false,
      }
    case IGNORE_TRIGGER_FAIL:
      return {
        ...state,
        repetitiveAction: false,
      }
    case ADD_TO_SPHERES_SUCCESS:
      return {
        ...state,
        repetitiveAction: false,
      }
    case ADD_PARTICIPANTS_TO_SPHERES_SUCCESS:
      return {
        ...state,
        repetitiveAction: false,
      }
    case ARCHIVE_PARTICIPANTS_SUCCESS:
      return {
        ...state,
        repetitiveAction: false,
      }
    case EDIT_CONTACTS_SUCCESS:
      return {
        ...state,
        repetitiveAction: false,
      }
    case APPROVE_TRIGGER_SUCCESS:
      return {
        ...state,
        repetitiveAction: false,
      }
    case IGNORE_TRIGGER_SUCCESS:
      return {
        ...state,
        repetitiveAction: false,
      }
    case EDIT_CONTACT:
      return {
        ...state,
        repetitiveAction: true,
      }
    case EDIT_CONTACT_FAIL:
      return {
        ...state,
        repetitiveAction: false,
      }
    case EDIT_CONTACT_SUCCESS:
      return {
        ...state,
        repetitiveAction: false,
      }

    default:
      return state
  }
}

import React, { useEffect, useState } from "react"
import "./TinyMCUEditor.scss"
import { notification, Spin } from "antd"

import { useSelector } from "react-redux"
import { Editor } from "@tinymce/tinymce-react"
import RelatableLoader from "../RelatableLoader/RelatableLoader.component"

const TinyMCUEditor = ({
  body,
  updateBody,
  receiverId,
  toolbarId,
  hidePersonalization = false,
  extras = [],
  focusHandler = null,
}) => {
  const [value, setValue] = useState(body ?? "")
  const loading = useSelector((state) => state.Messages.loading)

  useEffect(() => {
    setValue(body)
  }, [body])

  const upImg = (blobInfo, progress) =>
    new Promise((resolve, reject) => {
      const isLt1M = blobInfo.blob().size / 1024 / 1024 < 1
      if (isLt1M) {
        resolve(`data:image/png;base64,${blobInfo.base64()}`)
      } else {
        reject(
          "Image must be smaller than 1MB. If you want to send image bigger than 1MB, please add it as attachment."
        )
        notification.error({
          message: "Image",
          description:
            "Image must be smaller than 1MB. If you want to send image bigger than 1MB, please add it as attachment.",
          className: "Notification-error",
        })
      }
    })

  const renderPersonalization = (editor) => {
    return [
      {
        name: "First Name",
        value: "{{ first_name }}",
      },

      {
        name: "Last Name",
        value: "{{ last_name }}",
      },

      {
        name: "Title",
        value: "{{ one_liner }}",
      },

      {
        name: "Company",
        value: "{{ company }}",
      },
    ].map((t, idx) => ({
      type: "menuitem",
      text: t.name,
      onAction: () => {
        editor.insertContent(t.value)
      },
    }))
  }

  return (
    <>
      <RelatableLoader loading={loading} style={{ paddingTop: "7rem" }}>
        <div className={"TinyCustomEditor_Wrapper"}>
          <Editor
            onFocusIn={(e) => focusHandler && focusHandler(true, toolbarId)}
            apiKey="cna2g60i6v5mw87lu8vkkn18auptd3rd7sr3epm1931w9jpp"
            value={value}
            onFocusOut={(e, editor) => updateBody(value)}
            onEditorChange={(newValue, editor) => {
              setValue(newValue)
            }}
            init={{
              elementpath: false,
              branding: false,
              paste_data_images: false,
              forced_root_block: "div",
              selector: "textarea",
              height: 400,
              menubar: false,
              setup: (editor) => {
                !receiverId &&
                  !hidePersonalization &&
                  editor.ui.registry.addMenuButton("PersonalizationMenu", {
                    text: "Personalization",
                    fetch: (callback) => {
                      callback(renderPersonalization(editor))
                    },
                  })

                // editor.ui.registry.addButton("StripSpacesButton", {
                //   icon: <FileDoneOutlined />,
                //   onAction: handleClickStripSpaces,
                // })
              },
              contextmenu:
                "paste | link image inserttable | cell row column deletetable",
              plugins: [
                "advlist",
                "autolink",
                "lists",
                "link",
                "image",
                "charmap",
                "anchor",
                "searchreplace",
                "visualblocks",
                "fullscreen",
                "insertdatetime",
                "media",
                "image",
                "table",
                "code",
                "help",
                "wordcount",
              ],
              toolbar:
                "undo redo | blocks | " +
                "PersonalizationMenu |" +
                "bold italic forecolor |" +
                "bullist numlist | " +
                "image | code fullscreen |" +
                "alignleft aligncenter alignright alignjustify | outdent indent |" +
                "fontfamily fontsize |" +
                "StripSpacesButton removeformat | help",
              content_style:
                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
              file_picker_types: "file image media",
              images_upload_handler: upImg,
            }}
          />
          {extras?.length ? (
            <div className={"TinyCustomEditor_Extras"}>
              {extras.map((e, idx) => {
                return <div key={idx}>{e}</div>
              })}
            </div>
          ) : null}
        </div>
      </RelatableLoader>
    </>
  )
}

export default TinyMCUEditor

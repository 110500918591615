export const ADD_REMINDER = "ADD_REMINDER"
export const ADD_REMINDER_SUCCESS = "ADD_REMINDER_SUCCESS"
export const ADD_REMINDER_FAIL = "ADD_REMINDER_FAIL"

export const FETCH_REMINDERS = "FETCH_REMINDERS"
export const FETCH_REMINDERS_SUCCESS = "FETCH_REMINDERS_SUCCESS"
export const FETCH_REMINDERS_FAIL = "FETCH_REMINDERS_FAIL"

export const COMPLETE_REMINDER = "COMPLETE_REMINDER"
export const COMPLETE_REMINDER_SUCCESS = "COMPLETE_REMINDER_SUCCESS"
export const COMPLETE_REMINDER_FAIL = "COMPLETE_REMINDER_FAIL"

export const DELETE_REMINDER = "DELETE_REMINDER"
export const DELETE_REMINDER_SUCCESS = "DELETE_REMINDER_SUCCESS"
export const DELETE_REMINDER_FAIL = "DELETE_REMINDER_FAIL"

export const FETCH_EVENTS = "FETCH_EVENTS"
export const FETCH_EVENTS_SUCCESS = "FETCH_EVENTS_SUCCESS"
export const FETCH_EVENTS_FAIL = "FETCH_EVENTS_FAIL"

export const IGNORE_SPECIAL_DATE = "IGNORE_SPECIAL_DATE"
export const IGNORE_SPECIAL_DATE_SUCCESS = "IGNORE_SPECIAL_DATE_SUCCESS"
export const IGNORE_SPECIAL_DATE_FAIL = "IGNORE_SPECIAL_DATE_FAIL"

export const CLEAR_REDUX_REMINDERS_STATE = "CLEAR_REDUX_REMINDERS_STATE"
export const SET_INITIAL_REDUX_REMINDERS_STATE = "SET_INITIAL_REDUX_REMINDERS_STATE"

export const UPDATE_REMINDER = "UPDATE_REMINDER"
export const UPDATE_REMINDER_SUCCESS = "UPDATE_REMINDER_SUCCESS"
export const UPDATE_REMINDER_FAIL = "UPDATE_REMINDER_FAIL"

import {
  ADD_TEAM_MEMBER,
  ADD_TEAM_MEMBER_FAIL,
  ADD_TEAM_MEMBER_SUCCESS,
  AUTHENTICATE_USER,
  AUTHENTICATE_USER_FAIL,
  AUTHENTICATE_USER_SUCCESS,
  CANCEL_TEAM_INVITE,
  CANCEL_TEAM_INVITE_FAIL,
  CANCEL_TEAM_INVITE_SUCCESS,
  CLEAR_REDUX_USER_STATE,
  CREATE_API_KEY,
  CREATE_API_KEY_FAIL,
  CREATE_API_KEY_SUCCESS,
  CREATE_TEAM,
  CREATE_TEAM_FAIL,
  CREATE_TEAM_SUCCESS,
  DELETE_API_KEY,
  DELETE_API_KEY_FAIL,
  DELETE_API_KEY_SUCCESS,
  DELETE_TEAM,
  DELETE_TEAM_FAIL,
  DELETE_TEAM_SUCCESS,
  FETCH_TEAM,
  FETCH_TEAM_FAIL,
  FETCH_TEAM_SUCCESS,
  GET_API_KEYS,
  GET_API_KEYS_FAIL,
  GET_API_KEYS_SUCCESS,
  GET_STATUS,
  GET_STATUS_FAIL,
  GET_STATUS_SUCCESS,
  GET_TRIAL_INFO,
  GET_TRIAL_INFO_FAIL,
  GET_USER_DETAILS,
  GET_USER_DETAILS_FAIL,
  GET_USER_DETAILS_SUCCESS,
  GET_USER_FINISHED_TASKS,
  GET_USER_FINISHED_TASKS_FAIL,
  GET_USER_FINISHED_TASKS_SUCCESS,
  HIDE_ONBOARDING_FLOW,
  JOIN_TO_TEAM,
  JOIN_TO_TEAM_FAIL,
  JOIN_TO_TEAM_SUCCESS,
  LEAVE_TEAM,
  LEAVE_TEAM_FAIL,
  LEAVE_TEAM_SUCCESS,
  LOGOUT,
  LOGOUT_ALL_DEVICES,
  LOGOUT_ALL_DEVICES_FAIL,
  LOGOUT_ALL_DEVICES_SUCCESS,
  LOGOUT_FAIL,
  LOGOUT_START,
  LOGOUT_SUCCESS,
  PASSWORD_RESET,
  PASSWORD_RESET_FAIL,
  PASSWORD_RESET_SUCCESS,
  REGENERATE_API_KEY,
  REGENERATE_API_KEY_FAIL,
  REGENERATE_API_KEY_SUCCESS,
  REGISTER_USER,
  REGISTER_USER_FAIL,
  REGISTER_USER_SUCCESS,
  REMOVE_MEMBER_TEAM,
  REMOVE_MEMBER_TEAM_FAIL,
  REMOVE_MEMBER_TEAM_SUCCESS,
  REMOVE_TEAM_INVITATION,
  REMOVE_TEAM_INVITATION_FAIL,
  REMOVE_TEAM_INVITATION_SUCCESS,
  RESEND_TEAM_INVITATION,
  RESEND_TEAM_INVITATION_FAIL,
  RESEND_TEAM_INVITATION_SUCCESS,
  RESET_LOGGING_OUT_STATE,
  SAVE_PASSWORD,
  SAVE_PASSWORD_FAIL,
  SAVE_PASSWORD_SUCCESS,
  SEND_FOCUS_MODE_EVENT,
  SET_INITIAL_REDUX_USER_STATE,
  SET_REDIRECTING,
  SET_REFRESHING,
  UPDATE_AVATAR,
  UPDATE_AVATAR_FAIL,
  UPDATE_AVATAR_SUCCESS,
  UPDATE_PROFILE,
  UPDATE_PROFILE_FAIL,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_SHOW_FOCUS_MODE,
  UPDATE_TEAM,
  UPDATE_TEAM_FAIL,
  UPDATE_TEAM_SETTINGS,
  UPDATE_TEAM_SETTINGS_FAIL,
  UPDATE_TEAM_SETTINGS_SUCCESS,
  UPDATE_TEAM_SUCCESS,
  UPDATE_TIMEZONE,
} from "./User.types"

export const authenticateUser = (email, password, code, token, provider) => {
  const nonce = localStorage.getItem("nonce")
  localStorage.removeItem("nonce")
  return {
    type: AUTHENTICATE_USER,
    email: email,
    password: password,
    code: code,
    token: token,
    provider: provider,
    nonce: nonce,
  }
}

export const authenticateUserSuccess = (
  id,
  email,
  first_name,
  last_name,
  avatar,
  token,
  refresh,
  role,
  phone_number,
  daily_recommendations,
  track_opened,
  bio,
  leaderboard_name,
  trial_days_remaining
) => {
  return {
    type: AUTHENTICATE_USER_SUCCESS,
    id: id,
    email: email,
    first_name: first_name,
    last_name: last_name,
    avatar: avatar,
    token: token,
    refresh: refresh,
    role: role,
    phone_number: phone_number,
    daily_recommendations: daily_recommendations,
    track_opened: track_opened,
    bio: bio,
    leaderboard_name: leaderboard_name,
    trial_days_remaining: trial_days_remaining,
  }
}

export const setRefreshing = (value) => {
  return {
    type: SET_REFRESHING,
    refreshing: value,
  }
}

export const authenticateUserFail = (error) => {
  return {
    type: AUTHENTICATE_USER_FAIL,
    error: error,
  }
}

export const registerUser = ({
  first_name,
  last_name,
  email,
  password,
  referral,
  referral_string,
}) => {
  return {
    type: REGISTER_USER,
    first_name: first_name,
    last_name: last_name,
    email: email,
    password: password,
    referral: referral,
    referral_string: referral_string,
  }
}

export const registerUserSuccess = (id, email, first_name, last_name, avatar) => {
  return {
    type: REGISTER_USER_SUCCESS,
    id: id,
    email: email,
    first_name: first_name,
    last_name: last_name,
    avatar: avatar,
  }
}

export const registerUserFail = (error) => {
  return {
    type: REGISTER_USER_FAIL,
    error: error,
  }
}

export const updateProfile = (values) => {
  return {
    type: UPDATE_PROFILE,
    values: values,
  }
}

export const updateProfileSuccess = (values) => {
  return {
    type: UPDATE_PROFILE_SUCCESS,
    values: values,
  }
}

export const updateProfileFail = (error) => {
  return {
    type: UPDATE_PROFILE_FAIL,
    error: error,
  }
}

export const createTeam = (name) => {
  return {
    type: CREATE_TEAM,
    name: name,
  }
}

export const createTeamSuccess = (team) => {
  return {
    type: CREATE_TEAM_SUCCESS,
    team: team,
  }
}

export const createTeamFail = (error) => {
  return {
    type: CREATE_TEAM_FAIL,
    error: error,
  }
}

export const fetchTeam = () => {
  return {
    type: FETCH_TEAM,
  }
}

export const fetchTeamSuccess = (teams, team_invitations) => {
  return {
    type: FETCH_TEAM_SUCCESS,
    teams: teams,
    team_invitations: team_invitations,
  }
}

export const fetchTeamFail = (error) => {
  return {
    type: FETCH_TEAM_FAIL,
    error: error,
  }
}

export const removeTeamInvitation = (team_id) => {
  return {
    type: REMOVE_TEAM_INVITATION,
    team_id: team_id,
  }
}

export const removeTeamInvitationSuccess = (team_invitations) => {
  return {
    type: REMOVE_TEAM_INVITATION_SUCCESS,
    team_invitations: team_invitations,
  }
}

export const removeTeamInvitationFail = (error) => {
  return {
    type: REMOVE_TEAM_INVITATION_FAIL,
    error: error,
  }
}

export const joinToTeam = (team_id) => {
  return {
    type: JOIN_TO_TEAM,
    team_id: team_id,
  }
}

export const joinToTeamSuccess = (teams, team_invitations) => {
  return {
    type: JOIN_TO_TEAM_SUCCESS,
    teams: teams,
    team_invitations: team_invitations,
  }
}

export const joinToTeamFail = (error) => {
  return {
    type: JOIN_TO_TEAM_FAIL,
    error: error,
  }
}
export const deleteTeam = (id) => {
  return {
    type: DELETE_TEAM,
    id: id,
  }
}

export const deleteTeamSuccess = (removed_team_id) => {
  return {
    type: DELETE_TEAM_SUCCESS,
    removed_team_id: removed_team_id,
  }
}

export const deleteTeamFail = (error) => {
  return {
    type: DELETE_TEAM_FAIL,
    error: error,
  }
}

export const leaveTeam = (id, member_id) => {
  return {
    type: LEAVE_TEAM,
    id: id,
    member_id: member_id,
  }
}

export const leaveTeamSuccess = (leaved_team_id) => {
  return {
    type: LEAVE_TEAM_SUCCESS,
    leaved_team_id: leaved_team_id,
  }
}

export const leaveTeamFail = (error) => {
  return {
    type: LEAVE_TEAM_FAIL,
    error: error,
  }
}

export const addTeamMember = (id, email) => {
  return {
    type: ADD_TEAM_MEMBER,
    id: id,
    email: email,
  }
}

export const addTeamMemberSuccess = (team) => {
  return {
    type: ADD_TEAM_MEMBER_SUCCESS,
    team: team,
  }
}

export const addTeamMemberFail = (error) => {
  return {
    type: ADD_TEAM_MEMBER_FAIL,
    error: error,
  }
}

export const removeMemberTeam = (team_id, member_id) => {
  return {
    type: REMOVE_MEMBER_TEAM,
    team_id: team_id,
    member_id: member_id,
  }
}

export const removeMemberTeamSuccess = (team_id, member_id) => {
  return {
    type: REMOVE_MEMBER_TEAM_SUCCESS,
    team_id: team_id,
    member_id: member_id,
  }
}

export const removeMemberTeamFail = (error) => {
  return {
    type: REMOVE_MEMBER_TEAM_FAIL,
    error: error,
  }
}

export const cancelTeamInvite = (team_id, member_email) => {
  return {
    type: CANCEL_TEAM_INVITE,
    team_id: team_id,
    member_email: member_email,
  }
}

export const cancelTeamInviteSuccess = (team_id, member_email) => {
  return {
    type: CANCEL_TEAM_INVITE_SUCCESS,
    team_id: team_id,
    member_email: member_email,
  }
}

export const cancelTeamInviteFail = (error) => {
  return {
    type: CANCEL_TEAM_INVITE_FAIL,
    error: error,
  }
}

export const resendTeamInvitation = (team_id, member_email) => {
  return {
    type: RESEND_TEAM_INVITATION,
    team_id: team_id,
    member_email: member_email,
  }
}

export const resendTeamInvitationSuccess = () => {
  return {
    type: RESEND_TEAM_INVITATION_SUCCESS,
  }
}

export const resendTeamInvitationFail = (error) => {
  return {
    type: RESEND_TEAM_INVITATION_FAIL,
    error: error,
  }
}

export const updateTeamSettings = (team_id, new_settings) => {
  return {
    type: UPDATE_TEAM_SETTINGS,
    team_id: team_id,
    new_settings: new_settings,
  }
}

export const updateTeamSettingsSuccess = (team_id, new_settings) => {
  return {
    type: UPDATE_TEAM_SETTINGS_SUCCESS,
    team_id: team_id,
    new_settings: new_settings,
  }
}

export const updateTeamSettingsFail = (error) => {
  return {
    type: UPDATE_TEAM_SETTINGS_FAIL,
    error: error,
  }
}

export const UpdateTeam = (id, name) => {
  return {
    type: UPDATE_TEAM,
    id: id,
    name: name,
  }
}

export const UpdateTeamSuccess = (teams) => {
  return {
    type: UPDATE_TEAM_SUCCESS,
    teams: teams,
  }
}

export const UpdateTeamFail = (error) => {
  return {
    type: UPDATE_TEAM_FAIL,
    error: error,
  }
}

export const updateAvatar = (avatar) => {
  return {
    type: UPDATE_AVATAR,
    avatar: avatar,
  }
}

export const updateAvatarSuccess = (user) => {
  return {
    type: UPDATE_AVATAR_SUCCESS,
    user: user,
  }
}

export const updateAvatarFail = (error) => {
  return {
    type: UPDATE_AVATAR_FAIL,
    error: error,
  }
}

export const logout = (force) => {
  return {
    type: LOGOUT,
    force: force,
  }
}

export const logoutStart = () => {
  return {
    type: LOGOUT_START,
  }
}

export const logoutSuccess = () => {
  return {
    type: LOGOUT_SUCCESS,
  }
}

export const logoutFail = (error) => {
  return {
    type: LOGOUT_FAIL,
    error: error,
  }
}

export const resetLoggingOutState = () => {
  return {
    type: RESET_LOGGING_OUT_STATE,
  }
}

export const getStatus = () => {
  return {
    type: GET_STATUS,
  }
}
export const getStatusSuccess = (
  status,
  role,
  created_at,
  is_trialing,
  trial_days_remaining,
  billing_account_status,
  has_push_registration
) => {
  return {
    type: GET_STATUS_SUCCESS,
    status: status,
    role: role,
    created_at: created_at,
    is_trialing: is_trialing,
    trial_days_remaining: trial_days_remaining,
    billing_account_status: billing_account_status,
    has_push_registration: has_push_registration,
  }
}
export const getStatusFail = (error) => {
  return {
    type: GET_STATUS_FAIL,
    error: error,
  }
}

export const resetPassword = (email) => {
  return {
    type: PASSWORD_RESET,
    email: email,
  }
}
export const resetPasswordSuccess = () => {
  return {
    type: PASSWORD_RESET_SUCCESS,
  }
}
export const resetPasswordFail = (error) => {
  return {
    type: PASSWORD_RESET_FAIL,
    error: error,
  }
}

export const savePassword = (password, token) => {
  return {
    type: SAVE_PASSWORD,
    password: password,
    token: token,
  }
}
export const savePasswordSuccess = () => {
  return {
    type: SAVE_PASSWORD_SUCCESS,
  }
}
export const savePasswordFail = (error) => {
  return {
    type: SAVE_PASSWORD_FAIL,
    error: error,
  }
}

export const clearReduxUserState = () => {
  return {
    type: CLEAR_REDUX_USER_STATE,
  }
}

export const setInitialReduxUserState = () => {
  return {
    type: SET_INITIAL_REDUX_USER_STATE,
  }
}

export const getTrialInfo = () => {
  return {
    type: GET_TRIAL_INFO,
  }
}

export const getTrialInfoFail = (error) => {
  return {
    type: GET_TRIAL_INFO_FAIL,
    error: error,
  }
}

export const getUserDetails = () => {
  return {
    type: GET_USER_DETAILS,
  }
}
export const getUserDetailsSuccess = (
  id,
  email,
  first_name,
  last_name,
  avatar,
  phone_number,
  daily_recommendations,
  track_opened,
  bio,
  leaderboard_name,
  public_username,
  trial_days_remaining,
  time_zone,
  qr_code,
  profile_filler_sphere,
  api_key,
  show_focus_mode
) => {
  return {
    type: GET_USER_DETAILS_SUCCESS,
    id: id,
    email: email,
    first_name: first_name,
    last_name: last_name,
    avatar: avatar,
    phone_number: phone_number,
    daily_recommendations: daily_recommendations,
    track_opened: track_opened,
    bio: bio,
    leaderboard_name: leaderboard_name,
    public_username: public_username,
    trial_days_remaining: trial_days_remaining,
    time_zone: time_zone,
    qr_code: qr_code,
    profile_filler_sphere: profile_filler_sphere,
    api_key: api_key,
    show_focus_mode: show_focus_mode,
  }
}
export const getUserDetailsFail = (error) => {
  return {
    type: GET_USER_DETAILS_FAIL,
    error: error,
  }
}

export const getUserFinishedTasks = () => {
  return {
    type: GET_USER_FINISHED_TASKS,
  }
}
export const getUserFinishedTasksSuccess = (finished_tasks) => {
  return {
    type: GET_USER_FINISHED_TASKS_SUCCESS,
    finished_tasks: finished_tasks,
  }
}
export const getUserFinishedTasksFail = (error) => {
  return {
    type: GET_USER_FINISHED_TASKS_FAIL,
    error: error,
  }
}

export const updateTimezone = (time_zone) => {
  return {
    type: UPDATE_TIMEZONE,
    time_zone: time_zone,
  }
}

export const logoutAllDevices = () => {
  return {
    type: LOGOUT_ALL_DEVICES,
  }
}

export const logoutAllDevicesSuccess = () => {
  return {
    type: LOGOUT_ALL_DEVICES_SUCCESS,
  }
}

export const logoutAllDevicesFail = (error) => {
  return {
    type: LOGOUT_ALL_DEVICES_FAIL,
    error: error,
  }
}

export const getAPIKeys = () => {
  return {
    type: GET_API_KEYS,
  }
}

export const getAPIKeysSuccess = (api_keys) => {
  return {
    type: GET_API_KEYS_SUCCESS,
    api_keys: api_keys,
  }
}

export const getAPIKeysFail = (error) => {
  return {
    type: GET_API_KEYS_FAIL,
    error: error,
  }
}

export const createAPIKey = (key_name) => {
  return {
    key_name: key_name,
    type: CREATE_API_KEY,
  }
}

export const createAPIKeySuccess = (api_keys) => {
  return {
    type: CREATE_API_KEY_SUCCESS,
    api_keys: api_keys,
  }
}

export const createAPIKeyFail = (error) => {
  return {
    type: CREATE_API_KEY_FAIL,
    error: error,
  }
}

export const regenerateAPIKey = (id) => {
  return {
    id: id,
    type: REGENERATE_API_KEY,
  }
}

export const regenerateAPIKeySuccess = (api_keys) => {
  return {
    type: REGENERATE_API_KEY_SUCCESS,
    api_keys: api_keys,
  }
}

export const regenerateAPIKeyFail = (error) => {
  return {
    type: REGENERATE_API_KEY_FAIL,
    error: error,
  }
}

export const deleteAPIKey = (id) => {
  return {
    id: id,
    type: DELETE_API_KEY,
  }
}

export const deleteAPIKeySuccess = (api_keys) => {
  return {
    type: DELETE_API_KEY_SUCCESS,
    api_keys: api_keys,
  }
}

export const deleteAPIKeyFail = (error) => {
  return {
    type: DELETE_API_KEY_FAIL,
    error: error,
  }
}

export const updateShowFocusMode = (show_focus_mode) => {
  return {
    type: UPDATE_SHOW_FOCUS_MODE,
    show_focus_mode: show_focus_mode,
  }
}

export const sendFocusModeEvent = (event_name, type_of_event = null) => {
  return {
    type: SEND_FOCUS_MODE_EVENT,
    event_name: event_name,
    type_of_event: type_of_event,
  }
}

export const hideOnboardingFlow = () => {
  return {
    type: HIDE_ONBOARDING_FLOW,
  }
}

export const setRedirecting = (redirecting) => {
  return {
    type: SET_REDIRECTING,
    redirecting: redirecting,
  }
}

import {
  CLEAR_REDUX_APP_STATE,
  DESELECT_CONTACT,
  DESELECT_CONTACTS,
  DESELECT_PARTICIPANT,
  DESELECT_PARTICIPANTS,
  FILL_DATA_IN_SELECTED_CONTACTS,
  OPEN_DRAWER_TAB,
  RESET_SELECTED_CONTACTS,
  SELECT_CONTACT,
  SELECT_CONTACTS,
  SELECT_PARTICIPANT,
  SELECT_PARTICIPANTS,
  SET_ACTIVE_TAB,
  SET_CONTACT_DRAWER_MODE,
  SET_CONTACT_DRAWER_VISIBLE,
  SET_CURRENT_LOCATION_KEY,
  SET_INIT_CC,
  SET_INITIAL_REDUX_APP_STATE,
  SET_LEFT_RAIL_COLLAPSED,
  SET_MESSAGE_SENDER_VISIBLE,
  SET_MESSAGE_SENT,
  SET_RIGHT_PANEL_TAB,
  SET_RIGHT_RAIL_COLLAPSED,
  SET_SCHEDULED_SPLIT_MESSAGE_SENDER_VISIBLE,
  SET_SELECTED_CONTACT_ID,
  SET_SHOW_START_BUILDING_CARD,
  SET_SPHERE_MODAL_VISIBLE,
  SET_SPLIT_MESSAGE_SENDER_VISIBLE,
  SET_USER_PANEL_MODAL_VISIBLE,
  SET_VISIBLE_CONTACT_ID,
  SHOW_NO_ACCOUNT_NOTIFICATION,
  TOGGLE_SUGGESTED_MESSAGES_PANEL,
  VALIDATION_ERROR,
} from "./App.types"

export const setContactDrawerVisible = (state) => {
  return {
    type: SET_CONTACT_DRAWER_VISIBLE,
    visible: state,
  }
}

export const setMessageSenderVisible = (state) => {
  return {
    type: SET_MESSAGE_SENDER_VISIBLE,
    visible: state,
  }
}

export const setSplitMessageSenderVisible = (state) => {
  return {
    type: SET_SPLIT_MESSAGE_SENDER_VISIBLE,
    visible: state,
  }
}

export const setScheduledSplitMessageSenderVisible = (state) => {
  return {
    type: SET_SCHEDULED_SPLIT_MESSAGE_SENDER_VISIBLE,
    visible: state,
  }
}

export const setContactDrawerMode = (mode) => {
  return {
    type: SET_CONTACT_DRAWER_MODE,
    mode: mode,
  }
}

export const setVisibleContactID = (id, slug) => {
  return {
    type: SET_VISIBLE_CONTACT_ID,
    id: id,
    slug: slug || id,
  }
}

export const setActiveTab = (tab) => {
  return {
    type: SET_ACTIVE_TAB,
    tab: tab,
  }
}

export const setLeftRailCollapsed = (collapsed) => {
  return {
    type: SET_LEFT_RAIL_COLLAPSED,
    leftRailCollapsed: collapsed,
  }
}

export const setRightRailCollapsed = (collapsed) => {
  return {
    type: SET_RIGHT_RAIL_COLLAPSED,
    rightRailCollapsed: collapsed,
  }
}

export const selectContact = (selected) => {
  return {
    type: SELECT_CONTACT,
    selected: selected,
  }
}

export const deselectContact = (selected) => {
  return {
    type: DESELECT_CONTACT,
    selected: selected,
  }
}

export const selectContacts = (selected) => {
  return {
    type: SELECT_CONTACTS,
    selected: selected,
  }
}

export const deselectContacts = () => {
  return {
    type: DESELECT_CONTACTS,
  }
}

export const fillDataInSelectedContacts = (selected) => {
  return {
    type: FILL_DATA_IN_SELECTED_CONTACTS,
    selected: selected,
  }
}

export const resetSelectedContacts = () => {
  return {
    type: RESET_SELECTED_CONTACTS,
  }
}

export const selectParticipant = (selected_id) => {
  return {
    type: SELECT_PARTICIPANT,
    selected_id: selected_id,
  }
}

export const deselectParticipant = (selected_id) => {
  return {
    type: DESELECT_PARTICIPANT,
    selected_id: selected_id,
  }
}

export const selectParticipants = (selected_ids) => {
  return {
    type: SELECT_PARTICIPANTS,
    selected_ids: selected_ids,
  }
}

export const deselectParticipants = () => {
  return {
    type: DESELECT_PARTICIPANTS,
  }
}

export const setCurrentLocationKey = (value) => {
  return {
    type: SET_CURRENT_LOCATION_KEY,
    value: value,
  }
}
export const showNoAccountNotification = () => {
  return {
    type: SHOW_NO_ACCOUNT_NOTIFICATION,
  }
}

export const clearReduxAppState = () => {
  return {
    type: CLEAR_REDUX_APP_STATE,
  }
}

export const setInitialReduxAppState = () => {
  return {
    type: SET_INITIAL_REDUX_APP_STATE,
  }
}
export const setRightPanelTab = (tab) => {
  return {
    type: SET_RIGHT_PANEL_TAB,
    value: tab,
  }
}
export const validationError = (errors) => {
  return {
    type: VALIDATION_ERROR,
    errors: errors,
  }
}
export const setSphereModalVisible = (visible, sphere, tab = "reminders") => {
  return {
    type: SET_SPHERE_MODAL_VISIBLE,
    visible: visible,
    sphere_id: sphere,
    tab: tab,
  }
}
export const setUserPanelModalVisible = (visible) => {
  return {
    type: SET_USER_PANEL_MODAL_VISIBLE,
    visible: visible,
  }
}

export const setInitCC = (initCC) => {
  return {
    type: SET_INIT_CC,
    initCC: initCC,
  }
}

export const openDrawerTab = (tab_name) => {
  return {
    type: OPEN_DRAWER_TAB,
    tab_name: tab_name,
  }
}

export const setMessageSent = (message_sent) => {
  return {
    type: SET_MESSAGE_SENT,
    message_sent: message_sent,
  }
}

export const setShowStartBuildingCard = (value) => {
  return {
    type: SET_SHOW_START_BUILDING_CARD,
    value: value,
  }
}

export const toggleSuggestedMessagesPanel = () => {
  return {
    type: TOGGLE_SUGGESTED_MESSAGES_PANEL,
  }
}
export const setSelectedContactId = (id) => {
  return {
    type: SET_SELECTED_CONTACT_ID,
    id: id,
  }
}

import { Col, Modal, Row, Steps } from "antd"
import React, { useState } from "react"
import "../RightPanel.styles.scss"
import { Link } from "react-router-dom"
import "./WelcomeTab.styles.scss"
import WelcomeChecklist from "../WelcomeChecklist/WelcomeChecklist.component"

const { Step } = Steps

const WelcomeTab = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [videoUrl, setVideoUrl] = useState(null)

  const showModal = (video_url) => {
    setVideoUrl(video_url)
    setIsModalVisible(true)
  }
  const handleOk = () => {
    setVideoUrl(null)
    setIsModalVisible(false)
  }
  const handleCancel = () => {
    setVideoUrl(null)
    setIsModalVisible(false)
  }

  return (
    <Col span={24} className="WelcomeTab">
      <Row type={"flex"} align={"middle"}>
        <Col span={24}>
          <Modal
            title="Relatable"
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            width={1000}
            destroyOnClose={true}
          >
            {videoUrl && (
              //   <iframe src="https://player.vimeo.com/video/906488424?h=ca1b386d5e&title=0&byline=0&portrait=0" width="640" height="397" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
              // <p><a href="https://vimeo.com/906488424">Relatable - Welcome and Quick Overview</a> from <a href="https://vimeo.com/user116425754">Zvi Band</a> on <a href="https://vimeo.com">Vimeo</a>.</p>
              <iframe
                src={videoUrl}
                title="Welcome_tab_video"
                width="1000"
                height="664"
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
              ></iframe>
            )}
          </Modal>
          <WelcomeChecklist />
          <h3>We're stoked to have you here.</h3>
          <p>
            We recorded a few videos just for you (and everyone else) on how to get
            set up with Relatable. Click on any of them below to watch, or visit our{" "}
            <a href="https://relatable.helpscoutdocs.com/">Knowledge Base</a>, which
            is about as exciting as the last Harry Potter book.
          </p>
          <Steps progressDot current={9} direction="vertical">
            <Step
              title="Quick Overview and Welcome"
              description={
                <div>
                  Learn the basics of Relatable to help you get up and running.
                  <div
                    className="RightPanel_Video"
                    onClick={() =>
                      showModal(
                        "https://player.vimeo.com/video/906488424?h=ca1b386d5e&title=0&byline=0&portrait=0"
                      )
                    }
                  >
                    <img
                      alt="Watch Video"
                      className={"video_placeholder"}
                      src={`${process.env.PUBLIC_URL}/intro_gifs/Relatable - Welcome and Quick Overview-low.gif`}
                    />
                  </div>
                </div>
              }
            />

            <Step
              title="Get Your Accounts Connected"
              description={
                <div>
                  <Link to="/account/settings">
                    Connecting your email and calendar accounts
                  </Link>{" "}
                  will bring in people you've been engaging with recently. Don't
                  worry, we don't automatically add everyone!
                  <div
                    className="RightPanel_Video"
                    onClick={() =>
                      showModal(
                        "https://player.vimeo.com/video/906488354?h=f41771e98c&title=0&byline=0&portrait=0"
                      )
                    }
                  >
                    <img
                      alt="Watch Video"
                      className={"video_placeholder"}
                      src={`${process.env.PUBLIC_URL}/intro_gifs/Connecting Accounts and Bringing In Relationships-low.gif`}
                    />
                  </div>
                </div>
              }
            />

            <Step
              title="Introducing Spheres"
              description={
                <div>
                  The glowing heart of your database are Spheres. Learn how they help
                  you get organized and stay in touch.
                  <div
                    className="RightPanel_Video"
                    onClick={() =>
                      showModal(
                        "https://player.vimeo.com/video/906488245?h=0f4909e2fc&title=0&byline=0&portrait=0"
                      )
                    }
                  >
                    <img
                      alt="Watch Video"
                      className={"video_placeholder"}
                      src={`${process.env.PUBLIC_URL}/intro_gifs/Introducing Spheres-low.gif`}
                    />
                  </div>
                </div>
              }
            />

            <Step
              title="Your Reminders & Dashboard"
              description={
                <div>
                  Every day, Relatable is there with suggestions to cultivate your
                  network of clients, collaborators, and ex-lovers. Learn how.
                  <div
                    className="RightPanel_Video"
                    onClick={() => {
                      showModal(
                        "https://player.vimeo.com/video/906488089?h=ef61d9acae&title=0&byline=0&portrait=0"
                      )
                    }}
                  >
                    <img
                      alt="Watch Video"
                      className={"video_placeholder"}
                      src={`${process.env.PUBLIC_URL}/intro_gifs/Your Dashboard and Reminders-low.gif`}
                    />
                  </div>
                </div>
              }
            />

            <Step
              title="Contact Profiles"
              description={
                <div>
                  You can store anything & everything you want about the people you
                  care about - their location, interests, expertise, and whether they
                  have that gene that makes cilantro taste like soap.
                  <div
                    className="RightPanel_Video"
                    onClick={() =>
                      showModal(
                        "https://player.vimeo.com/video/906487988?h=1396fc7c02&title=0&byline=0&portrait=0"
                      )
                    }
                  >
                    <img
                      alt="Watch Video"
                      className={"video_placeholder"}
                      src={`${process.env.PUBLIC_URL}/intro_gifs/Contact Information-low.gif`}
                    />
                  </div>
                </div>
              }
            />

            <Step
              title="Filtering, Viewing, and Bulk Updating Contacts"
              description={
                <div>
                  Relatable lets you build relationships at scale - so naturally,
                  what you can do with one person in Relatable, you can do with… more
                  than one.
                  <div
                    className="RightPanel_Video"
                    onClick={() =>
                      showModal(
                        "https://player.vimeo.com/video/906487886?h=a16dc9d3ad&title=0&byline=0&portrait=0"
                      )
                    }
                  >
                    <img
                      alt="Watch Video"
                      className={"video_placeholder"}
                      src={`${process.env.PUBLIC_URL}/intro_gifs/Filtering, Viewing, and Bulk Updating Contacts-low.gif`}
                    />
                  </div>
                </div>
              }
            />

            <Step
              title="Flow Spheres & Stage Spheres"
              description={
                <div>
                  Want to get a little bit more advanced? Track a sales process with
                  Stage Spheres, and set up some lightweight automation with Flow
                  Spheres.
                  <div
                    className="RightPanel_Video"
                    onClick={() =>
                      showModal(
                        "https://player.vimeo.com/video/906488184?h=50d7b97ee7&title=0&byline=0&portrait=0"
                      )
                    }
                  >
                    <img
                      alt="Watch Video"
                      className={"video_placeholder"}
                      src={`${process.env.PUBLIC_URL}/intro_gifs/Flow Spheres and Stage Spheres-low.gif`}
                    />
                  </div>
                </div>
              }
            />

            <Step
              title="Learn The Best Practices"
              description={
                <div>
                  A tool is useless unless you have a strategy in mind. Luckily,
                  we've got a course that will give you in the ins and outs of the
                  tactics and mindset behind cultivating your network. Free for you,
                  just because we like you.
                  <div className="RightPanel_Video">
                    <a
                      href="https://www.bemorerelatable.com/the-masterclass-relationships-are-your-most-important-asset?coupon=ILOVERELATABLE"
                      target="_blank"
                    >
                      <img
                        alt="Watch Video"
                        className={"video_placeholder"}
                        src={`${process.env.PUBLIC_URL}/intro_gifs/Learn The Best Practices-min.gif`}
                      />
                    </a>
                  </div>
                </div>
              }
            />
          </Steps>
        </Col>
      </Row>
    </Col>
  )
}

export default WelcomeTab
